import type { GroupCategory } from '@orus.eu/backend/src/events/collected-file/collected-file-event'
import { subscriptionDocumentDimensionByType, type SubscriptionDocumentType } from '@orus.eu/dimensions'
import {
  Chip,
  RowButtonV2,
  RowContainerV2,
  downloadFile,
  useDialogVisibility,
  useLanguage,
  type UploadedFile,
} from '@orus.eu/pharaoh'
import { SubscriptionDocumentUploadDialog } from '@orus.eu/pharaoh/src/components/features/document-collection'
import type { SubscriptionDocumentUploadDialogProps } from '@orus.eu/pharaoh/src/components/features/document-collection/subscription-document-upload-dialog/subscription-document-upload-dialog'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { trpcReact } from '../../../../../client'
import { getCollectedFileDownloadLink } from '../../../../../lib/download-links'
import { FUNNEL_LABEL_AND_VARIANT_PER_GROUP_STATUS } from './labels'
import type { File } from './types'
import { useUploaderStorageBackend } from './uploader-storage-backend'

type DocumentListProps = {
  subscriptionId: string
  neededDocuments: SubscriptionDocumentType[]
}

export const DocumentList = memo<DocumentListProps>(function DocumentList({ subscriptionId, neededDocuments }) {
  const language = useLanguage()
  const {
    hide: hideDocumentUploadDialog,
    show: showDocumentUploadDialog,
    visible: isDocumentUploadDialogVisible,
  } = useDialogVisibility('add-document')

  const [dialogProps, setDialogProps] = useState<SubscriptionDocumentUploadDialogProps | undefined>()

  const [currentFileList, setCurrentFileList] = useState<UploadedFile[]>([])
  const [currentDocumentType, setCurrentDocumentType] = useState<SubscriptionDocumentType | undefined>()

  const collectedFiles = trpcReact.collectedFile.getAllFiles.useQuery({ subscriptionId })
  const { mutate: mutateDeleteFile } = trpcReact.collectedFile.deleteFile.useMutation({
    onSuccess: () => collectedFiles.refetch(),
  })

  const { storageBackend } = useUploaderStorageBackend({
    subscriptionId,
    category: currentDocumentType ?? null,
    onUploaded: () => void collectedFiles.refetch(),
  })

  const filesPerCategory = useMemo(
    () =>
      Object.values(collectedFiles.data?.files ?? {}).reduce<Partial<Record<GroupCategory | 'none', File[]>>>(
        (acc, file) => {
          if (file.deleted) return acc
          const groupCategory = (file.groupId ? collectedFiles.data?.groups[file.groupId]?.category : 'none') ?? 'none'

          acc[groupCategory] = (acc[groupCategory] ?? []).concat(file)
          return acc
        },
        {},
      ),
    [collectedFiles.data?.files, collectedFiles.data?.groups],
  )

  const getFiles: () => Promise<UploadedFile[]> = useCallback(() => {
    return Promise.resolve(currentFileList)
  }, [currentFileList])

  const downloadUploadedFile: (file: UploadedFile) => Promise<void> = useCallback(
    (file) => {
      if (currentDocumentType) {
        const link = getCollectedFileDownloadLink(file.id)
        return downloadFile(link, file.name, file.mimeType)
      } else return Promise.reject('No document type selected')
    },
    [currentDocumentType],
  )

  const removeFile: (file: UploadedFile) => Promise<void> = useCallback(
    (uploadedFile) => {
      const file = collectedFiles.data?.files[uploadedFile.id]
      if (!file || file.deleted) {
        return Promise.resolve()
      }

      mutateDeleteFile({ fileId: file.id, etag: file.etag })

      return Promise.resolve()
    },
    [collectedFiles, mutateDeleteFile],
  )

  const handleClose = useCallback(() => {
    setCurrentDocumentType(undefined)
    hideDocumentUploadDialog()
  }, [hideDocumentUploadDialog])

  useEffect(() => {
    if (!currentDocumentType) return

    const group = Object.values(collectedFiles.data?.groups ?? {}).find(
      (group) => group.category === currentDocumentType,
    )
    const files = group ? (filesPerCategory[group.category] ?? []) : []

    setCurrentFileList(
      files.map((file) => ({
        type: 'uploaded',
        name: file.title,
        id: file.id,
        uploadTimestamp: file.collectionDate.getTime(),
        mimeType: file.mimeType,
      })),
    )
    showDocumentUploadDialog()
  }, [currentDocumentType, collectedFiles.data?.groups, filesPerCategory, showDocumentUploadDialog])

  useEffect(() => {
    if (!currentDocumentType) return

    setDialogProps({
      documentType: currentDocumentType,
      storageBackend: {
        ...storageBackend,
        getFiles,
        removeFile,
        downloadFile: downloadUploadedFile,
      },
      onClose: handleClose,
    })
  }, [storageBackend, currentDocumentType, downloadUploadedFile, getFiles, handleClose, removeFile])

  return (
    <>
      <RowContainerV2 noContainedBorder={true}>
        {neededDocuments?.map((documentType) => {
          const group = Object.values(collectedFiles.data?.groups ?? {}).find(
            (group) => group.category === documentType,
          )

          const chipConfig = FUNNEL_LABEL_AND_VARIANT_PER_GROUP_STATUS[group?.status ?? 'empty']

          return (
            <RowButtonV2
              key={documentType}
              primaryText={subscriptionDocumentDimensionByType[documentType].displayNames[language]}
              avatarRight={
                <Chip variant={chipConfig.variant} dark={chipConfig.dark} size="large">
                  {chipConfig.label}
                </Chip>
              }
              onClick={() => {
                setCurrentDocumentType(documentType)
              }}
            />
          )
        })}
      </RowContainerV2>

      {isDocumentUploadDialogVisible && dialogProps && <SubscriptionDocumentUploadDialog {...dialogProps} />}
    </>
  )
})
