import { css } from '@emotion/react'
// eslint-disable-next-line no-restricted-imports
import { Collapse } from '@mui/material'
import { DataGrid, type GridColDef, type GridRenderCellParams, type GridValidRowModel } from '@mui/x-data-grid'
import { frFR } from '@mui/x-data-grid/locales/frFR'
import type {
  CollectedFileStatus,
  GroupCategory,
} from '@orus.eu/backend/src/events/collected-file/collected-file-event'
import type { Group } from '@orus.eu/backend/src/services/collected-documents/collected-file-service'
import { Button, Checkbox, Chip, spacing, Text, Tooltip, Uploader, useDialogVisibility } from '@orus.eu/pharaoh'
import { memo, useMemo, useRef, useState } from 'react'
import { trpcReact } from '../../../../../client'
import { LABEL_AND_VARIANT_PER_FILE_STATUS, LABEL_AND_VARIANT_PER_GROUP_STATUS } from './chip-config'
import { DeleteFileDialog } from './delete-file-dialog'
import { LABEL_PER_DOCUMENT_CATEGORY } from './labels'
import type { File } from './types'
import { useUploaderStorageBackend } from './uploader-storage-backend'

export const FileGroup = memo(function FileGroup({
  subscriptionId,
  category,
  group,
  files,
  initiallyCollapsed,
  stateLoading,
  allowedCategories,
  invalidate,
  onViewClick,
}: {
  subscriptionId: string
  category: GroupCategory | null
  group: Group | null
  initiallyCollapsed: boolean
  files: File[]
  stateLoading: boolean
  allowedCategories: GroupCategory[]
  invalidate: () => void
  onViewClick: (fileId: string) => void
}) {
  const deleteFileDialog = useDialogVisibility(`delete_file_${category ?? 'uncategorized'}`)
  const fileToDeleteRef = useRef<{ fileId: string; fileTitle: string; fileEtag: string } | null>(null)

  const [isCollapsed, setIsCollapsed] = useState(initiallyCollapsed)
  const { storageBackend, uploadInProgress, setUploadInProgress } = useUploaderStorageBackend({
    subscriptionId,
    category: category ?? null,
    onUploaded: invalidate,
  })

  const updateFileStatus = trpcReact.collectedFile.updateFileStatus.useMutation({
    onSuccess: invalidate,
  })
  const updateFileTitle = trpcReact.collectedFile.updateFileTitle.useMutation({
    onSuccess: invalidate,
  })
  const updateFileCategory = trpcReact.collectedFile.updateFileCategory.useMutation({
    onSuccess: invalidate,
  })
  const updateGroupIncomplete = trpcReact.collectedFile.updateGroupIncomplete.useMutation({
    onSuccess: invalidate,
  })

  const loading =
    stateLoading ||
    uploadInProgress ||
    updateFileStatus.isPending ||
    updateFileTitle.isPending ||
    updateFileCategory.isPending

  const rows = files.map((file) => ({
    id: file.id,
    etag: file.etag,
    title: file.title,
    status: file.status,
    category: group?.category ?? null,
  }))

  const columns: GridColDef[] = useMemo(
    () => [
      { field: 'title', headerName: 'Titre', editable: true, flex: 2 },
      {
        field: 'status',
        headerName: 'Statut',
        type: 'singleSelect',
        valueOptions: Object.entries(LABEL_AND_VARIANT_PER_FILE_STATUS).map(([status, data]) => ({
          value: status,
          label: data.label,
        })),
        editable: true,
        width: 100,
        renderCell: (params: GridRenderCellParams<GridValidRowModel, CollectedFileStatus>) => {
          const status = params.value

          if (!status) return null

          const data = LABEL_AND_VARIANT_PER_FILE_STATUS[status]

          return (
            <Chip size="small" variant={data.variant} dark>
              {data.label}
            </Chip>
          )
        },
      },
      {
        field: 'category',
        type: 'singleSelect',
        valueOptions: allowedCategories.map((category) => ({
          value: category,
          label: LABEL_PER_DOCUMENT_CATEGORY[category],
        })),
        headerName: 'Catégorie',
        editable: true,
        width: 200,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        renderCell: (params) => (
          <div css={css({ display: 'inline-flex', gap: spacing['30'] })}>
            <Button variant="secondary" size="small" icon="eye-solid" onClick={() => onViewClick(params.row.id)} />
            <Button
              variant="secondary"
              size="small"
              icon="trash-can-solid"
              onClick={() => {
                fileToDeleteRef.current = {
                  fileId: params.row.id,
                  fileEtag: params.row.etag,
                  fileTitle: params.row.title,
                }
                deleteFileDialog.show()
              }}
            />
          </div>
        ),
      },
    ],
    [deleteFileDialog, allowedCategories, onViewClick],
  )

  return (
    <>
      <div css={css({ display: 'flex', flexDirection: 'column' })}>
        <div css={css({ display: 'flex', gap: spacing[30], alignItems: 'center' })}>
          <Button
            variant="text"
            size="small"
            icon={isCollapsed || rows.length === 0 ? 'angle-right-regular' : 'angle-down-regular'}
            onClick={() => setIsCollapsed(!isCollapsed)}
            disabled={rows.length === 0}
          />
          <Text variant="subtitle1">{category ? LABEL_PER_DOCUMENT_CATEGORY[category] : 'Non catégorisé'}</Text>
          {group ? (
            <>
              <Chip
                size="small"
                variant={LABEL_AND_VARIANT_PER_GROUP_STATUS[group.status].variant}
                dark={LABEL_AND_VARIANT_PER_GROUP_STATUS[group.status].dark}
              >
                {LABEL_AND_VARIANT_PER_GROUP_STATUS[group.status].label}
              </Chip>
              {group.status !== 'validated' && group.status !== 'incomplete' ? null : (
                <Tooltip title={group.incomplete ? 'Marquer comme complet' : 'Marquer comme incomplet'}>
                  <Checkbox
                    size="small"
                    checked={!group.incomplete}
                    onChange={(checked) =>
                      updateGroupIncomplete.mutate({ etag: group.etag, incomplete: !checked, groupId: group.id })
                    }
                  />
                </Tooltip>
              )}
            </>
          ) : category ? (
            <Chip
              size="small"
              variant={LABEL_AND_VARIANT_PER_GROUP_STATUS.empty.variant}
              dark={LABEL_AND_VARIANT_PER_GROUP_STATUS.empty.dark}
            >
              Vide
            </Chip>
          ) : null}
          <Uploader
            isBackoffice
            size="small"
            storageBackend={storageBackend}
            setIsUploadInProgress={setUploadInProgress}
            css={css({ flex: 1, maxWidth: '300px' })}
          />
        </div>

        <Collapse in={!isCollapsed && rows.length > 0}>
          <div css={css({ display: 'flex', flexDirection: 'column', gap: spacing[60], marginTop: spacing[60] })}>
            {rows.length > 0 ? (
              <DataGrid
                loading={loading}
                rows={rows}
                columns={columns}
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                disableColumnMenu
                disableRowSelectionOnClick
                pageSizeOptions={[100]}
                processRowUpdate={(updatedRow: Row, originalRow: Row) => {
                  if (updatedRow.status !== originalRow.status) {
                    updateFileStatus.mutate({
                      fileId: updatedRow.id,
                      status: updatedRow.status,
                      etag: updatedRow.etag,
                    })
                  } else if (updatedRow.title !== originalRow.title) {
                    updateFileTitle.mutate({
                      fileId: updatedRow.id,
                      title: updatedRow.title,
                      etag: updatedRow.etag,
                    })
                  } else if (updatedRow.category && updatedRow.category !== originalRow.category) {
                    updateFileCategory.mutate({
                      fileId: updatedRow.id,
                      category: updatedRow.category,
                      etag: updatedRow.etag,
                    })
                  }

                  return updatedRow
                }}
              />
            ) : null}
          </div>
        </Collapse>
      </div>

      {fileToDeleteRef.current && deleteFileDialog.visible ? (
        <DeleteFileDialog
          fileId={fileToDeleteRef.current.fileId}
          fileEtag={fileToDeleteRef.current.fileEtag}
          fileTitle={fileToDeleteRef.current.fileTitle}
          onSuccess={invalidate}
          close={deleteFileDialog.hide}
        />
      ) : null}
    </>
  )
})

type Row = {
  id: string
  etag: string
  title: string
  status: CollectedFileStatus
  category: GroupCategory | null
}
