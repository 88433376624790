import type { TermsUiElement } from '@orus.eu/dimensions'
import {
  downloadFileFromBuffer,
  enqueueTemporaryNotificationAlert,
  TemporaryProblemError,
  useCrash,
  useLanguage,
} from '@orus.eu/pharaoh'
import { RowContainer } from '@orus.eu/pharaoh/src/components/rows'
import { isFailure } from '@orus.eu/result'
import { useParams } from '@tanstack/react-router'
import { useCallback, useMemo } from 'react'
import { trpc, trpcReact } from '../../../../client'
import { type ContractDocumentDescription, dynamicFileLabels } from '../../../../lib/contract-util'
import { dynamicFileNames, staticFileNames } from '../../../../lib/download-filenames'
import { getAnyGeneralTermsDocumentDownloadLink } from '../../../../lib/download-links'
import { usePermissions } from '../../../../lib/use-permissions'
import { ContractDocumentDownloadRow } from '../../../organisms/contract-documents-download-row'
import { ifStateProxy } from '../if-state-proxy'

export const TermsUiElementBlock = ifStateProxy<TermsUiElement>(function TermsUiElementBlock({
  subscriptionId,
  stateProxy,
  uiElement,
  changes,
}) {
  const language = useLanguage()
  const crash = useCrash()
  const { type: userType } = usePermissions()
  const { organization } = useParams({ strict: false })

  const files = useMemo(
    () => (stateProxy.read(uiElement.dimensions.generalTermsGroups) ?? []).flatMap((document) => document.documents),
    [stateProxy, uiElement.dimensions.generalTermsGroups],
  )

  const canGenerateAgreedTermsDraftApi = trpcReact.subscriptions.canGenerateAgreedTermsDraft.useQuery({
    subscriptionId,
    changes,
  })

  const downloadAgreedTerms = useCallback(async () => {
    if (!canGenerateAgreedTermsDraftApi.data) {
      enqueueTemporaryNotificationAlert('Retrieving if you can generate agreed terms draft', {
        variant: 'danger',
      })
      return
    }

    const result = await trpc.subscriptions.generateAgreedTermsDraftV2.mutate({
      subscriptionId,
      changes,
      organization,
    })

    if (isFailure(result)) {
      if (result.problem.type === 'temporary-api-failure') {
        crash(new TemporaryProblemError())
        return
      }
      enqueueTemporaryNotificationAlert(result.problem.explanation, {
        variant: 'danger',
      })
      return
    }

    const email = stateProxy.read(uiElement.dimensions.email)
    const fileName = email
      ? dynamicFileNames['agreed-terms-draft'](email, language)
      : staticFileNames['agreed-terms-draft'](language)

    await downloadFileFromBuffer(result.output, fileName)
  }, [
    canGenerateAgreedTermsDraftApi.data,
    organization,
    subscriptionId,
    changes,
    stateProxy,
    uiElement.dimensions.email,
    crash,
    language,
  ])

  const documents: Array<ContractDocumentDescription> = useMemo(
    () => [
      {
        method: 'post',
        fileName: dynamicFileLabels['agreed-terms-draft'](language),
        onClick: downloadAgreedTerms,
      } as const,
      ...files.map(
        (file): ContractDocumentDescription => ({
          method: 'get',
          fileName: file.fileName,
          fileType: 'agreed-terms',
          url: getAnyGeneralTermsDocumentDownloadLink({
            subscriptionId,
            file,
            isPartner: userType === 'partner' && !!organization,
            organization: organization ?? '',
          }),
        }),
      ),
    ],
    [downloadAgreedTerms, files, language, organization, subscriptionId, userType],
  )

  return (
    <RowContainer variant="border" size="medium">
      {documents.map((doc) => (
        <ContractDocumentDownloadRow key={doc.fileName} doc={doc} />
      ))}
    </RowContainer>
  )
})
