import { Dialog, Text } from '@orus.eu/pharaoh'
import { memo } from 'react'
import { trpcReact } from '../../../../../client'

export const DeleteFileDialog = memo<{
  fileEtag: string
  fileId: string
  fileTitle: string
  onSuccess: () => void
  close: () => void
}>(function DeleteFileDialog({ fileEtag, fileId, fileTitle, onSuccess, close }) {
  const deleteFile = trpcReact.collectedFile.deleteFile.useMutation({
    onSuccess: () => {
      onSuccess()
      close()
    },
  })

  return (
    <Dialog
      size="small"
      style="danger"
      isBackoffice
      onClose={close}
      primaryActionLabel="Supprimer"
      primaryActionLoading={deleteFile.isPending}
      secondaryActionLabel="Annuler"
      onSecondaryAction={close}
      onPrimaryAction={() => {
        deleteFile.mutate({ fileId, etag: fileEtag })
      }}
    >
      <Text>
        Êtes-vous sûr de vouloir supprimer le fichier <strong>{fileTitle}</strong> ?
      </Text>
    </Dialog>
  )
})
