import type { GroupData } from '@orus.eu/backend/src/views/activity-grouping-view'
import {
  productInformationIdToProduct,
  type ActivityBackofficeSearchSubscriptionUiElement,
  type PossibleOffer,
  type ProductInformationId,
} from '@orus.eu/dimensions'
import { checkDefinedAndNotNull } from '@orus.eu/error'
import type { OperatingZone } from '@orus.eu/operating-zone'
import { TextInputLabelWrapper, useAsyncCallback } from '@orus.eu/pharaoh'
import type { Product } from '@orus.eu/product'
import { useEffect, useState } from 'react'
import { trpc } from '../../../../client'
import { backofficeGroupMappingLoader } from '../../../../lib/group-mapping-util'
import { ActivityGroupSearchField } from '../../../molecules/activity-group-search-field'
import { ifStateProxy } from '../if-state-proxy'

export const ActivityBackofficeSearchSubscriptionUiElementBlock =
  ifStateProxy<ActivityBackofficeSearchSubscriptionUiElement>(
    function ActivityBackofficeSearchSubscriptionUiElementBlock(props) {
      const { uiElement, stateProxy } = props

      const [loading, setLoading] = useState(false)
      const [hasEligibleMainActivity, setHasEligibleMainActivity] = useState(false)
      const [hasReachedMaxActivities, setHasReachedMaxActivities] = useState(false)
      const [isSearchDisabled, setIsSearchDisabled] = useState(false)
      const [selectedActivityIds, setSelectedActivityIds] = useState<Set<string>>(new Set())

      const operatingZone = stateProxy.readRequired<OperatingZone>(uiElement.dimensions.operatingZone)
      const mainActivity = stateProxy.read(uiElement.dimensions.activity)
      const complementaryActivities = stateProxy.read(uiElement.dimensions.complementaryActivities)
      const rcphProduct = stateProxy.read(uiElement.dimensions.rcphProduct)
      const possibleOffers = stateProxy.read(uiElement.dimensions.possibleOffers)

      let product: Product | undefined = undefined
      if (possibleOffers) {
        const possibleOffersEntries = Object.entries(possibleOffers) as [ProductInformationId, PossibleOffer][]
        possibleOffersEntries.forEach(([productInformationId, offer]) => {
          if (offer.isRecommended) {
            product = productInformationIdToProduct[productInformationId]
          }
        })
      }

      useEffect(() => {
        const hasMainActivity = !!mainActivity
        setHasReachedMaxActivities(complementaryActivities?.length === 4)

        if (hasMainActivity) {
          const rcphSelected = stateProxy.read(uiElement.dimensions.rcphSelected)
          const rcdaSelected = stateProxy.read(uiElement.dimensions.rcdaSelected)
          const isRcphOrRcdaSelected = !!rcphSelected || !!rcdaSelected

          setIsSearchDisabled(loading || hasReachedMaxActivities || !isRcphOrRcdaSelected)
          if (mainActivity.activity === 'excludedActivity') {
            setHasEligibleMainActivity(false)
            return
          }

          setHasEligibleMainActivity(true)
          setSelectedActivityIds(
            new Set([
              ...(mainActivity?.activity ? [mainActivity.activity] : []),
              ...(complementaryActivities?.length ? complementaryActivities.map((activity) => activity.activity) : []),
            ]),
          )
        } else {
          setIsSearchDisabled(loading)
          setHasEligibleMainActivity(false)
        }
      }, [complementaryActivities, hasReachedMaxActivities, loading, mainActivity, stateProxy, uiElement.dimensions])

      const handleGroupSelected = useAsyncCallback(
        async (activityGroup: GroupData) => {
          setLoading(true)

          if (hasEligibleMainActivity) {
            if (hasReachedMaxActivities) return

            stateProxy.write(uiElement.dimensions.complementaryActivities, [
              ...(complementaryActivities ?? []),
              { activity: activityGroup.id, displayName: activityGroup.name },
            ])
          } else {
            const { mainActivityId } = checkDefinedAndNotNull(
              await trpc.activities.getGroupMappingForBackoffice.query({ group: activityGroup.name, operatingZone }),
            )

            stateProxy.write(uiElement.dimensions.activity, {
              activity: mainActivityId,
              displayName: activityGroup.name,
            })
          }

          setLoading(false)
        },
        [
          complementaryActivities,
          hasEligibleMainActivity,
          hasReachedMaxActivities,
          operatingZone,
          stateProxy,
          uiElement.dimensions,
        ],
      )

      return (
        <TextInputLabelWrapper label="Ajouter une activité">
          <ActivityGroupSearchField
            operatingZone={operatingZone}
            disabled={isSearchDisabled}
            groupLoader={backofficeGroupMappingLoader}
            onChange={handleGroupSelected}
            hasMainActivity={hasEligibleMainActivity}
            rcphProduct={rcphProduct}
            product={product}
            selectedActivityIds={selectedActivityIds}
          />
        </TextInputLabelWrapper>
      )
    },
  )
