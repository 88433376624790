import { css } from '@emotion/react'
import {
  AbstractDimension,
  getElementDimensions,
  type SubscriptionCondition,
  type SubscriptionElementType,
  type SubscriptionUiElement,
  type SubscriptionUiElementGroup,
} from '@orus.eu/dimensions'
import { Form, FormRow, spacing, SubSection, useUiContext, type Spacing, type UiContext } from '@orus.eu/pharaoh'

import { checkDefinedAndNotNull } from '@orus.eu/error'
import { memo, type NamedExoticComponent, type ReactNode } from 'react'
import { ActivitiesDistributionSubscriptionUiElementBlock } from './elements/activities-distribution-element/ActivitiesDistributionSubscriptionUiElementBlock'
import { ActivitiesSelectorSubscriptionUiElementBlock } from './elements/activities-selector-element'
import { ActivityBackofficeSearchSubscriptionUiElementBlock } from './elements/activity-bo-search-element'
import { ActivityCategorySearchSubscriptionUiElementBlock } from './elements/activity-category-search-element'
import { ActivitySearchSubscriptionUiElementBlock } from './elements/activity-search-element'
import { ActivitySpecificQuestionsSubscriptionUiElementBlock } from './elements/activity-specific-questions-element'
import { ActivitySelectedInfoSubscriptionUiElementBlock } from './elements/ActivitySelectedInfoSubscriptionUiElementBlock'
import { AddBeneficiariesUiElementBlock } from './elements/add-beneficiaries-element'
import { AddressMapSubscriptionUiElementBlock } from './elements/address-map-element'
import { AmountSubscriptionUiElementBlock } from './elements/amount-element'
import { BankAccountSubscriptionUiElementBlock } from './elements/bank-account-element'
import { BooleanSubscriptionUiElementBlock } from './elements/boolean-element'
import { BrokerDiscountUiElementBlock } from './elements/BrokerDiscountUiElementBlock'
import { CalendarDateSubscriptionUiElementBlock } from './elements/calendar-date-element'
import { CallToHonestyBannerSubscriptionUiElementBlock } from './elements/call-to-honesty-banner-element'
import { CalloutSubscriptionUiElementBlock } from './elements/callout-element'
import { CheckoutSubscriptionUiElementBlock } from './elements/checkout-element'
import { ChoiceSubscriptionUiElementBlock } from './elements/choice-element'
import { ChooseMutaFormulaUiElementBlock } from './elements/choose-muta-formula-element'
import { ContactBannerSubscriptionUiElementBlock } from './elements/contact-banner-element'
import { CountrySubscriptionUiElementBlock } from './elements/country-element'
import { CustomClausesUiElementBlock } from './elements/custom-clauses-element/ClausesUiElementBlock'
import { DataPolicyAcceptanceSubscriptionUiElementBlock } from './elements/DataPolicyAcceptanceSubscriptionUiElementBlock'
import { DetailedSheetUiElementBlock } from './elements/detailed-subscription-element/DetailedSheetUiElementBlock'
import { DisabledMutaSocialSecurityRegimeTypeDimensionSubscriptionUiElementBlock } from './elements/disabled-muta-regime-element'
import { DisabledStringDimensionSubscriptionUiElementBlock } from './elements/disabled-string-dimension-element'
import { DiscountUiElementBlock } from './elements/discount-element'
import { SubscriptionDocumentsUiElementBlock } from './elements/documents-list-element/documents-element'
import { EmailAvailabilityCheckerSubscriptionUiElementBlock } from './elements/email-availability-checker-element'
import { EmptySubscriptionUiElementBlock } from './elements/empty-element'
import { EmptyStateQuoteUiElementBlock } from './elements/empty-state-quote-element'
import { FeeRateUiElementBlock } from './elements/FeeRateUiElementBlock'
import { FinancialRateSubscriptionUiElementBlock } from './elements/financial-rate-element'
import { GuaranteeDetailSubscriptionElementUiBlock } from './elements/guarantee-detail-element'
import { InstallmentFeeUiElementBlock } from './elements/installment-fee-element'
import { IntegerSubscriptionUiElementBlock } from './elements/integer-element'
import { InvoicingConfigurationUiElementBlock } from './elements/invoicing-configuration-element'
import { LockedSubscriptionUiElementBlock } from './elements/locked-element'
import { AddFullBeneficiariesBoUiElementBlock } from './elements/muta-beneficiaries-element/add-full-beneficiaries-bo-element'
import { FinalizeBeneficiariesSubscriptionUiElementBlock } from './elements/muta-beneficiaries-element/finalize-beneficiaries-element'
import { OperatorUiElementBlock } from './elements/OperatorUiElementBlock'
import { PartnerApplicationFeeUiElementBlock } from './elements/PartnerApplicationFeeUiElementBlock'
import { PartnerFeesCardUiElementBlock } from './elements/PartnerFeesCardUiElementBlock'
import { PaymentRecurrenceElementBlock } from './elements/payment-recurrence-element'
import { PersonalDataBannerSubscriptionUiElementBlock } from './elements/personal-data-banner-element'
import { PersonalDataOverQuoteSubscriptionUiElementBlock } from './elements/personal-data-over-quote-modal'
import { PriceCardElementBlock } from './elements/price-card-element'
import { ProductIndicatorUiElementBlock } from './elements/product-indicator-element'
import { ProductSelectionBackofficeUiElementBlock } from './elements/product-selection-bo-element'
import { QuotePageBodyElementBlock } from './elements/quote-page-body-element'
import { QuotePageSubtitleElementBlock } from './elements/quote-page-subtitle-element/QuotePageSubtitleElement'
import { ReadonlyCurrentCountrySubscriptionUiElementBlock } from './elements/readonly-current-country-element'
import { RevenueLimitSubscriptionUiElementBlock } from './elements/revenue-limit-element'
import { SeparatorSubscriptionUiElementBlock } from './elements/separator-element'
import { SirenSubscriptionUiElementBlock } from './elements/siren-element'
import { SiretSubscriptionUiElementBlock } from './elements/siret-element'
import { SiretSearchSubscriptionUiElementBlock } from './elements/siret-search/siret-search-element'
import { SocialSecurityNumberSubscriptionUiElementBlock } from './elements/social-security-number-element'
import { SocialSecurityOrganismNumberSubscriptionUiElementBlock } from './elements/social-security-organism-number-element'
import { StatementSubscriptionUiElementBlock } from './elements/statement-element'
import { StatementRefusalUiElementBlock } from './elements/StatementRefusalUiElementBlock'
import { StringSubscriptionUiElementBlock } from './elements/string-element'
import { SurfaceSubscriptionUiElementBlock } from './elements/surface-element'
import { TermsUiElementBlock } from './elements/TermsUiElementBlock'
import { TextSubscriptionUiElementBlock } from './elements/text-element'
import { TrustUiElementBlock } from './elements/trust-element'
import {
  createStateProxy,
  useSubscriptionElementProps,
  type CommonSubscriptionStepProps,
  type StateProxy,
  type SubscriptionElementBlockProps,
} from './subscription-v2-props'

type SubscriptionElementGroupBlockProps = CommonSubscriptionStepProps & {
  group: SubscriptionUiElementGroup
  className?: string
}

type SubscriptionBackofficeElementGroupBlockProps = SubscriptionElementGroupBlockProps & {
  requiredDimensionsNames: string[]
  highlightDimensionsNames: string[]
  addMissingDimension: (dimensionName: string) => void
  removeMissingDimension: (dimensionName: string) => void
}

export const SubscriptionBackofficeElementGroupBlock = memo<SubscriptionBackofficeElementGroupBlockProps>(
  function SubscriptionBackofficeElementGroupBlock({
    group,
    className,
    backofficeStepSubtitle,
    backofficeStepTitle,
    requiredDimensionsNames,
    highlightDimensionsNames,
    addMissingDimension,
    removeMissingDimension,
    ...stepProps
  }) {
    type ReactNodeFormRowElements = { elements: ReactNode; isSingleMini?: boolean }[]

    const rows: Record<number, ReactNodeFormRowElements> = {}

    let indexCurrentRow = 0

    for (let uiElementIndex = 0; uiElementIndex < group.length; uiElementIndex++) {
      const initialUiElement = group[uiElementIndex]
      const verticalMargins = getUiElementVerticalMargins(initialUiElement)

      const { localState, updateLocalChanges, updateAndPersistLocalChanges } = stepProps
      const allowedDimensions = getElementDimensions(initialUiElement)
      const stateProxy = createStateProxy({
        localState,
        updateLocalChanges,
        updateAndPersistLocalChanges,
        allowedDimensions,
      })

      if (!shouldDisplayBackofficeElement(initialUiElement, stateProxy)) {
        continue
      }

      const uiElement = highlightElementNeeded({
        element: initialUiElement,
        requiredDimensionsNames,
        highlightDimensionsNames,
        addMissingDimension,
        removeMissingDimension,
        stateProxy,
      })

      if (rows[indexCurrentRow] === undefined) rows[indexCurrentRow] = []

      if (uiElement.isSingleLineElement || uiElement.goToNewBackofficeLine) {
        if (rows[indexCurrentRow].length > 0) {
          indexCurrentRow = indexCurrentRow + 1
        }
        rows[indexCurrentRow] = [
          {
            elements: (
              <SubscriptionBackofficeElementWrapper
                key={uiElementIndex}
                verticalMargins={verticalMargins}
                uiElement={uiElement}
                {...stepProps}
              />
            ),
            isSingleMini: uiElement.isSingleLineElement?.isSingleMini,
          },
        ]
        if (uiElement.isSingleLineElement) indexCurrentRow = indexCurrentRow + 1
        continue
      }

      if (rows[indexCurrentRow].length < 3) {
        rows[indexCurrentRow].push({
          elements: (
            <SubscriptionBackofficeElementWrapper
              key={uiElementIndex}
              verticalMargins={verticalMargins}
              uiElement={uiElement}
              {...stepProps}
            />
          ),
          isSingleMini: false,
        })
      } else {
        indexCurrentRow = indexCurrentRow + 1
        rows[indexCurrentRow] = [
          {
            elements: (
              <SubscriptionBackofficeElementWrapper
                key={uiElementIndex}
                verticalMargins={verticalMargins}
                uiElement={uiElement}
                {...stepProps}
              />
            ),
            isSingleMini: false,
          },
        ]
      }
    }

    const formContent = Object.values(rows)

    return formContent.map((row) => row.flatMap((row) => row.elements)).length === 0 ? (
      <></>
    ) : (
      <SubSection title={backofficeStepTitle} subtitle={backofficeStepSubtitle}>
        <Form>
          {formContent.map((rows, index) => {
            return (
              <FormRow key={index} isSingleMini={rows[0].isSingleMini}>
                {rows.map((row) => row.elements)}
              </FormRow>
            )
          })}
        </Form>
      </SubSection>
    )
  },
)

function highlightElementNeeded({
  element,
  requiredDimensionsNames,
  highlightDimensionsNames,
  addMissingDimension,
  removeMissingDimension,
  stateProxy,
}: {
  element: SubscriptionUiElement
  requiredDimensionsNames: string[]
  highlightDimensionsNames: string[]
  addMissingDimension: (dimensionName: string) => void
  removeMissingDimension: (dimensionName: string) => void
  stateProxy: StateProxy | undefined
}): SubscriptionUiElement {
  if ('dimension' in element) {
    const dimension: AbstractDimension = element.dimension
    element.required = requiredDimensionsNames.includes(dimension.name) ? true : undefined

    element.highlight =
      highlightDimensionsNames.includes(dimension.name) &&
      (stateProxy?.read(dimension) === null || stateProxy?.read(dimension) === undefined)
        ? true
        : undefined

    if (element.highlight) {
      addMissingDimension(dimension.name)
    } else {
      removeMissingDimension(dimension.name)
    }
  }
  if ('dimensions' in element) {
    for (const dimension of Object.values(element.dimensions)) {
      element.required = requiredDimensionsNames.includes(dimension.name) ? true : undefined

      element.highlight =
        highlightDimensionsNames.includes(dimension.name) &&
        (stateProxy?.read(dimension) === null || stateProxy?.read(dimension) === undefined)
          ? true
          : undefined

      if (element.highlight) {
        addMissingDimension(dimension.name)
      } else {
        removeMissingDimension(dimension.name)
      }
      break
    }
  }

  return element
}

function shouldDisplayBackofficeElement(uiElement: SubscriptionUiElement, stateProxy: StateProxy | undefined): boolean {
  if (uiElement.hideInBackoffice) return false
  if (!uiElement.condition || (stateProxy && evaluateCondition(uiElement.condition, stateProxy))) return true

  return false
}

export const SubscriptionElementGroupBlock = memo<SubscriptionElementGroupBlockProps>(
  function SubscriptionElementGroupBlock({
    group,
    className,
    backofficeStepSubtitle,
    backofficeStepTitle,
    ...stepProps
  }) {
    const screenType = useUiContext()

    /**
     * We are going to build the children of the react element representing the group.
     * Children will be either a single ui element, or a group of columns in which each
     * contains UI elements.
     */
    const children: ReactNode[] = []

    /**
     * This array is going to contain the group of columns we are currently buiding, as we
     * iterate through the items and discover if they need to be grouped.
     * It will be undefined at times when no group of columns is being build.
     */
    let currentGroupOfNarrowItems: SubscriptionUiElement[] | undefined = undefined

    for (let uiElementIndex = 0; uiElementIndex < group.length; uiElementIndex++) {
      const uiElement = group[uiElementIndex]
      const verticalMargins = getUiElementVerticalMargins(uiElement)

      if (shouldGroupUiElement(screenType, uiElement)) {
        // the item wants to be grouped in columns, so we're going to insert it in a column group.

        if (currentGroupOfNarrowItems) {
          // a group is already build built, so let's add it to the current group
          currentGroupOfNarrowItems.push(uiElement)
          checkDefinedAndNotNull(currentGroupOfNarrowItems)
        } else {
          // No group is currently being built, so let's create a new column group with this element
          currentGroupOfNarrowItems = [uiElement]
        }

        if (currentGroupOfNarrowItems.length === groupedNarrowItemsColumnCount) {
          // the column group has reach maximum length, so let's create the corresponding
          // react element and push it to the children.

          children.push(<Columns key={'c' + uiElementIndex} uiElements={currentGroupOfNarrowItems} {...stepProps} />)
          currentGroupOfNarrowItems = undefined
        }
        // If the group size is odd, as grouping is done by even elements, we push the last element.
        if (currentGroupOfNarrowItems && uiElementIndex === group.length - 1) {
          children.push(<Columns key={'c' + uiElementIndex} uiElements={currentGroupOfNarrowItems} {...stepProps} />)
          currentGroupOfNarrowItems = undefined
        }
      } else {
        // elements doesn't want to be groupped in columns, so we're going to add it directly

        if (currentGroupOfNarrowItems) {
          // a group of columns was being built, so we need to add it to the children first.

          children.push(<Columns key={'c' + uiElementIndex} uiElements={currentGroupOfNarrowItems} {...stepProps} />)
          currentGroupOfNarrowItems = undefined
        }

        children.push(
          <SubscriptionElementWrapper
            key={uiElementIndex}
            verticalMargins={verticalMargins}
            uiElement={uiElement}
            {...stepProps}
          />,
        )
      }
    }

    return <div className={className}>{children}</div>
  },
)

function shouldGroupUiElement(screenVariant: UiContext, uiElement: SubscriptionUiElement): boolean {
  const width = (screenVariant === 'backoffice' ? uiElement.layout?.boWidth : undefined) ?? uiElement.layout?.width
  return screenVariant === 'desktop' && width === 'narrow'
}

function evaluateCondition(condition: SubscriptionCondition | undefined, stateProxy: StateProxy): boolean {
  if (!condition) return true
  if ('and' in condition) return condition.and.every((subCondition) => evaluateCondition(subCondition, stateProxy))
  if ('or' in condition) return condition.or.some((subCondition) => evaluateCondition(subCondition, stateProxy))
  if ('not' in condition) return !evaluateCondition(condition.not, stateProxy)
  if ('defined' in condition) return stateProxy.read(condition.defined) != undefined
  if ('notEmpty' in condition) {
    const arrayElements = stateProxy.read(condition.notEmpty)
    return Array.isArray(arrayElements) && arrayElements.length > 0
  }
  const value = stateProxy.read(condition)
  return !!value
}
type ColumnsProps = CommonSubscriptionStepProps & { uiElements: SubscriptionUiElement[] }
const Columns = memo<ColumnsProps>(function GroupedFields(props) {
  const { uiElements, ...passedProps } = props
  const verticalMargins = getMaxVerticalMargins(uiElements)

  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: repeat(${groupedNarrowItemsColumnCount}, minmax(0, 1fr));
        gap: ${spacing[50]};
        align-items: start;
        margin-top: ${verticalMargins === 0 ? '0px' : spacing[verticalMargins]};
        margin-bottom: ${verticalMargins === 0 ? '0px' : spacing[verticalMargins]};

        &:first-of-type {
          margin-top: 0;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      `}
    >
      {uiElements.map((uiElement, uiElementIndex) => (
        <SubscriptionElementWrapper key={uiElementIndex} verticalMargins={0} uiElement={uiElement} {...passedProps} />
      ))}
    </div>
  )
})

export const SubscriptionBackofficeElementWrapper = memo<
  CommonSubscriptionStepProps & { uiElement: SubscriptionUiElement; verticalMargins: 0 | Spacing }
>(function SubscriptionElementWrapper(props) {
  const passedProps = useSubscriptionElementProps(props)

  const shouldHide = props.uiElement.hideInBackoffice

  if (shouldHide) return <></>

  return !props.uiElement.condition ||
    (passedProps.stateProxy && evaluateCondition(props.uiElement.condition, passedProps.stateProxy)) ? (
    <SubscriptionElementBlock {...passedProps} />
  ) : (
    <></>
  )
})

export const SubscriptionElementWrapper = memo<
  CommonSubscriptionStepProps & { uiElement: SubscriptionUiElement; verticalMargins: 0 | Spacing }
>(function SubscriptionElementWrapper(props) {
  const passedProps = useSubscriptionElementProps(props)

  const shouldHide =
    (props.context === 'selfonboarding' && props.uiElement.hideInSelfOnboarding) ||
    (props.context === 'backoffice' && props.uiElement.hideInBackoffice)
  // XXX it looks like requiredRole is not being used throughout the code base
  // || (props.uiElement.requiredRole !== undefined && !session.roles.includes(props.uiElement.requiredRole))

  if (shouldHide) return <></>

  return !props.uiElement.condition ||
    (passedProps.stateProxy && evaluateCondition(props.uiElement.condition, passedProps.stateProxy)) ? (
    <div
      css={css`
        margin-top: ${props.verticalMargins === 0 ? '0px' : spacing[props.verticalMargins]};
        margin-bottom: ${props.verticalMargins === 0 ? '0px' : spacing[props.verticalMargins]};

        &:first-of-type {
          margin-top: 0;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      `}
    >
      <SubscriptionElementBlock {...passedProps} />
    </div>
  ) : (
    <></>
  )
})

export const SubscriptionElementBlock = memo<SubscriptionElementBlockProps<SubscriptionUiElement>>(
  function SubscriptionElementBlock(props) {
    const Component = uiElementComponents[props.uiElement.type]

    if (!Component) return <></>

    // @ts-expect-error I don't know how to convince typescript that it is valid :-/
    return <Component {...props} />
  },
)

const uiElementComponents: {
  [key in SubscriptionElementType]:
    | NamedExoticComponent<SubscriptionElementBlockProps<SubscriptionUiElement & { type: key }>>
    | undefined
} = {
  string: StringSubscriptionUiElementBlock,
  integer: IntegerSubscriptionUiElementBlock,
  'positive-integer': IntegerSubscriptionUiElementBlock,
  boolean: BooleanSubscriptionUiElementBlock,
  email: StringSubscriptionUiElementBlock,
  phone: StringSubscriptionUiElementBlock,
  postcode: StringSubscriptionUiElementBlock,
  separator: SeparatorSubscriptionUiElementBlock,
  'product-indicator': ProductIndicatorUiElementBlock,
  'quote-page-subtitle': QuotePageSubtitleElementBlock,
  'quote-page-body': QuotePageBodyElementBlock,
  'price-card': PriceCardElementBlock,
  'payment-recurrence': PaymentRecurrenceElementBlock,
  'calendar-date': CalendarDateSubscriptionUiElementBlock,
  statement: StatementSubscriptionUiElementBlock,
  checkout: CheckoutSubscriptionUiElementBlock,
  'guarantee-detail': GuaranteeDetailSubscriptionElementUiBlock,
  amount: AmountSubscriptionUiElementBlock,
  'financial-rate': FinancialRateSubscriptionUiElementBlock,
  choice: ChoiceSubscriptionUiElementBlock,
  surface: SurfaceSubscriptionUiElementBlock,
  'siret-search': SiretSearchSubscriptionUiElementBlock,
  siret: SiretSubscriptionUiElementBlock,
  siren: SirenSubscriptionUiElementBlock,
  'address-map': AddressMapSubscriptionUiElementBlock,
  'readonly-current-country': ReadonlyCurrentCountrySubscriptionUiElementBlock,
  'disabled-string-dimension': DisabledStringDimensionSubscriptionUiElementBlock,
  subtitle: TextSubscriptionUiElementBlock,
  empty: EmptySubscriptionUiElementBlock,
  'contact-banner': ContactBannerSubscriptionUiElementBlock,
  'personal-data-banner': PersonalDataBannerSubscriptionUiElementBlock,
  'personal-data-over-quote-modal': PersonalDataOverQuoteSubscriptionUiElementBlock,
  'call-to-honesty-banner': CallToHonestyBannerSubscriptionUiElementBlock,
  trust: TrustUiElementBlock,
  'activity-specific-questions': ActivitySpecificQuestionsSubscriptionUiElementBlock,
  'activity-selected-info': ActivitySelectedInfoSubscriptionUiElementBlock,
  'activity-search-main': ActivitySearchSubscriptionUiElementBlock,
  'activity-category-search': ActivityCategorySearchSubscriptionUiElementBlock,
  'add-beneficiaries': AddBeneficiariesUiElementBlock,
  'finalize-beneficiaries': FinalizeBeneficiariesSubscriptionUiElementBlock,
  country: CountrySubscriptionUiElementBlock,
  'disabled-muta-social-security-regime-dimension':
    DisabledMutaSocialSecurityRegimeTypeDimensionSubscriptionUiElementBlock,
  'social-security-number': SocialSecurityNumberSubscriptionUiElementBlock,
  'social-security-organism-number': SocialSecurityOrganismNumberSubscriptionUiElementBlock,
  'choose-muta-formula': ChooseMutaFormulaUiElementBlock,
  'add-full-beneficiaries-bo': AddFullBeneficiariesBoUiElementBlock,
  'bank-account': BankAccountSubscriptionUiElementBlock,
  'activities-selector': ActivitiesSelectorSubscriptionUiElementBlock,
  'activities-distribution': ActivitiesDistributionSubscriptionUiElementBlock,
  'rcda-revenue-limit': RevenueLimitSubscriptionUiElementBlock,
  callout: CalloutSubscriptionUiElementBlock,
  locked: LockedSubscriptionUiElementBlock,
  'email-availablitiy-checker': EmailAvailabilityCheckerSubscriptionUiElementBlock,
  'subscription-documents-element': SubscriptionDocumentsUiElementBlock,
  'activity-bo-search': ActivityBackofficeSearchSubscriptionUiElementBlock,
  'product-selection-bo': ProductSelectionBackofficeUiElementBlock,
  operator: OperatorUiElementBlock,
  'empty-state-quote': EmptyStateQuoteUiElementBlock,
  'invoicing-configuration': InvoicingConfigurationUiElementBlock,
  'custom-clauses': CustomClausesUiElementBlock,
  terms: TermsUiElementBlock,
  'installment-fee': InstallmentFeeUiElementBlock,
  'fee-rate': FeeRateUiElementBlock,
  'partner-application-fee': PartnerApplicationFeeUiElementBlock,
  'partner-fees-card': PartnerFeesCardUiElementBlock,
  discount: DiscountUiElementBlock,
  'broker-discount': BrokerDiscountUiElementBlock,
  'data-policy-acceptance': DataPolicyAcceptanceSubscriptionUiElementBlock,
  'statement-refusal': StatementRefusalUiElementBlock,
  'detailed-sheet': DetailedSheetUiElementBlock,
}

const groupedNarrowItemsColumnCount = 2

const returnOne = (): Spacing => 50
const returnTwo = (): Spacing => 70

const uiElementVerticalMarginFormulas: {
  [key in SubscriptionElementType]: (uiElement: SubscriptionUiElement & { type: key }) => Spacing
} = {
  string: returnOne,
  integer: returnOne,
  'positive-integer': returnOne,
  boolean: (uiElement) => (uiElement.variant === 'checkbox-card' ? 30 : 70),
  email: returnOne,
  phone: returnOne,
  postcode: returnOne,
  separator: returnTwo,
  'product-indicator': returnOne,
  'quote-page-subtitle': returnTwo,
  'quote-page-body': returnTwo,
  'price-card': returnTwo,
  'payment-recurrence': returnTwo,
  'calendar-date': returnOne,
  statement: returnTwo,
  checkout: returnTwo,
  locked: returnTwo,
  'guarantee-detail': returnTwo,
  amount: returnOne,
  'financial-rate': returnOne,
  choice: (uiElement) => (uiElement.variant === 'select' ? 50 : 70),
  surface: returnOne,
  'siret-search': returnOne,
  siret: returnOne,
  siren: returnOne,
  'address-map': returnTwo,
  'readonly-current-country': returnOne,
  'disabled-string-dimension': returnOne,
  subtitle: returnTwo,
  empty: returnTwo,
  'contact-banner': returnTwo,
  'personal-data-banner': returnTwo,
  'personal-data-over-quote-modal': returnOne,
  'call-to-honesty-banner': returnTwo,
  trust: returnTwo,
  'activity-specific-questions': () => 30,
  'activity-selected-info': returnTwo,
  'activity-search-main': returnTwo,
  'activity-category-search': returnTwo,
  'add-beneficiaries': returnTwo,
  'finalize-beneficiaries': returnTwo,
  country: returnOne,
  'disabled-muta-social-security-regime-dimension': returnOne,
  'social-security-number': returnOne,
  'social-security-organism-number': returnOne,
  'choose-muta-formula': returnOne,
  'activities-distribution': returnOne,
  'add-full-beneficiaries-bo': returnOne,
  'bank-account': returnOne,
  'activities-selector': returnOne,
  'rcda-revenue-limit': returnOne,
  callout: returnOne,
  'email-availablitiy-checker': returnOne,
  'subscription-documents-element': returnOne,
  'activity-bo-search': returnOne,
  'product-selection-bo': returnOne,
  operator: returnOne,
  'empty-state-quote': returnTwo,
  'invoicing-configuration': returnTwo,
  terms: returnOne,
  'custom-clauses': returnOne,
  'installment-fee': returnOne,
  'fee-rate': returnOne,
  'partner-application-fee': returnOne,
  'partner-fees-card': returnOne,
  discount: returnOne,
  'broker-discount': returnOne,
  'data-policy-acceptance': () => 70,
  'statement-refusal': returnOne,
  'detailed-sheet': returnOne,
}

function getUiElementVerticalMargins<Type extends SubscriptionElementType>(
  uiElement: SubscriptionUiElement & { type: Type },
): Spacing {
  const type: Type = uiElement.type
  const formula = uiElementVerticalMarginFormulas[type]
  return formula(uiElement)
}

function getMaxVerticalMargins(uiElements: SubscriptionUiElement[]): 0 | Spacing {
  let maxVerticalMargin: 0 | Spacing = 0
  for (const uiElement of uiElements) {
    const uiElementVerticalMargins = getUiElementVerticalMargins(uiElement)
    if (uiElementVerticalMargins > maxVerticalMargin) {
      maxVerticalMargin = uiElementVerticalMargins
    }
  }
  return maxVerticalMargin
}
