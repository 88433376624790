import { css } from '@emotion/react'
import { X0 } from '@orus.eu/amount'
import {
  getDiscountRate,
  getSubscriptionDocumentEmail,
  type LockedSubscriptionUiElement,
  type ProductAttributes,
} from '@orus.eu/dimensions'
import { Avatar, ButtonLink, CheckoutLayout, colors, spacing, Text } from '@orus.eu/pharaoh'
import { useMemo } from 'react'
import { trpcReact } from '../../../../client'
import { CopyToClipboardButton } from '../../../molecules/copy-to-clipboard-button'
import { PrepareSignatureLoadingState } from '../../subscription/checkout/shared-subscription-checkout-review-page'
import SharedSubscriptionContractElement from '../../subscription/checkout/shared-subscription-contract-element'
import { ifStateProxy } from '../if-state-proxy'
import { DocumentList } from './documents-list-element/document-list'

export const LockedSubscriptionUiElementBlock = ifStateProxy<LockedSubscriptionUiElement>(
  function LockedSubscriptionUiElementBlock({ uiElement, stateProxy, subscriptionId }) {
    const signaturePreparationData = trpcReact.subscriptions.getSignaturePreparationData.useQuery(subscriptionId)

    const discount = stateProxy.read(uiElement.dimensions.discount)
    const discountRate = getDiscountRate(discount) ?? X0

    const neededDocuments = stateProxy.readRequired(uiElement.dimensions.neededSubscriptionDocuments)

    const products = stateProxy.readRequired(uiElement.dimensions.offerInformation).products
    const productsAttributes = useMemo<ProductAttributes[]>(
      () =>
        products.map((product) => ({
          productName: product.name,
          attributes: product.attributes,
        })),
      [products],
    )

    const oneTime = stateProxy.read(uiElement.dimensions.rcdaQuote)?.oneTime
    const documentsEmail = getSubscriptionDocumentEmail({
      rcdaSelected: stateProxy.readRequired(uiElement.dimensions.rcdaSelected),
    })

    const operatingZone = stateProxy.readRequired(uiElement.dimensions.operatingZone)

    if (!signaturePreparationData.data) return <PrepareSignatureLoadingState />

    return (
      <CheckoutLayout
        goBack={undefined}
        contractColumnWidth="100%"
        documents={
          <SharedSubscriptionContractElement
            subscriptionId={subscriptionId}
            contractFileId={signaturePreparationData.data.signableFileId}
            quote={stateProxy.readRequired(uiElement.dimensions.quote)}
            generalTermsGroups={stateProxy.readRequired(uiElement.dimensions.generalTermsGroups)}
            commitment={stateProxy.readRequired(uiElement.dimensions.commitment)}
            forbiddenMonthlyPayment={stateProxy.readRequired(uiElement.dimensions.forbiddenMonthlyPayment)}
            discount={discountRate}
            productsAttributes={productsAttributes}
            startDate={stateProxy.readRequired(uiElement.dimensions.startDate)}
            yearlyCommitmentHasDiscount={stateProxy.readRequired(uiElement.dimensions.yearlyCommitmentHasDiscount)}
            historyTakeoverPrice={
              oneTime && oneTime.final.options.historyTakeover?.active
                ? oneTime.final.options.historyTakeover?.premium.total
                : undefined
            }
            partnerApplicationFee={oneTime ? oneTime.final.options.partnerApplicationFee?.premium.total : undefined}
            operatingZone={operatingZone}
          />
        }
        payment={
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: ${spacing[70]};
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: ${spacing[50]};
              `}
            >
              <Avatar icon="circle-check-regular" size="50" />
              <Text variant="h5">Merci pour votre demande de souscription</Text>
              <Text variant="body2">
                Vos informations vont être transmises à nos services. Un conseiller va vous rappeler pour finaliser
                votre dossier.
              </Text>
            </div>

            <DocumentList neededDocuments={neededDocuments} subscriptionId={subscriptionId} />

            <Text
              variant="body2"
              color={colors.gray[700]}
              css={css`
                text-align: center;
              `}
            >
              Besoin d’aide ou des questions vos pièces justificatives, <br />
              contactez-nous à cette adresse
            </Text>

            <div
              css={css`
                display: flex;
                justify-content: center;
                gap: ${spacing[50]};
              `}
            >
              <ButtonLink
                to={`mailto:${documentsEmail}`}
                variant="secondary"
                size="medium"
                icon="envelope-regular"
                avatarPosition="left"
              >
                {documentsEmail}
              </ButtonLink>

              <CopyToClipboardButton
                message="L'adresse email a bien été copiée dans le presse-papier"
                stringToCopy={documentsEmail}
                title="Copier l'email"
                icon="copy-regular"
                variant="secondary"
              />
            </div>
          </div>
        }
      />
    )
  },
)
