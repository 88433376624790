import {
  enqueueTemporaryNotificationAlert,
  type LoadedFile,
  type UploadedFile,
  type UploaderStorageBackend,
} from '@orus.eu/pharaoh'
import { useCallback, useState } from 'react'

export const UPLOAD_FILES_PATH = '/rest/collected-files'

export function useUploaderStorageBackend({
  subscriptionId,
  category,
  onUploaded,
}: {
  subscriptionId: string
  category: string | null
  onUploaded: () => void
}): {
  storageBackend: UploaderStorageBackend
  uploadInProgress: boolean
  setUploadInProgress: (uploadInProgress: boolean) => void
} {
  const [uploadInProgress, setUploadInProgress] = useState(false)

  const uploadFile: (file: LoadedFile) => Promise<UploadedFile> = useCallback(
    (file) => {
      const formData = new FormData()
      formData.append('file', file.file)
      formData.append('metadata', JSON.stringify({ name: file.name, subscriptionId, category }))

      return fetch(UPLOAD_FILES_PATH, {
        method: 'POST',
        body: formData,
        cache: 'no-store',
      }).then(async (response) => {
        if (response.status !== 200) {
          // Error management to improve :
          // - review the router to expose different types of failures
          // - functionnally handle user mistakes (wrong file type, etc.)
          // - send sentry using logger.errorfor unexpected failures (bad request, etc)
          enqueueTemporaryNotificationAlert('Echec de l’envoi du fichier', { variant: 'danger' })
        }

        onUploaded()

        return {
          type: 'uploaded',
          name: file.name,
          id: '',
          uploadTimestamp: 0,
          mimeType: file.file.type,
        }
      })
    },
    [subscriptionId, category, onUploaded],
  )

  return {
    storageBackend: {
      getFiles: () => Promise.resolve([]),
      removeFile: () => Promise.resolve(),
      uploadFile,
      downloadFile: () => Promise.resolve(),
      updateFile: () => Promise.resolve(),
    },
    uploadInProgress,
    setUploadInProgress,
  }
}
