import { css } from '@emotion/react'
import { formatFrenchAddress1Line } from '@orus.eu/address'
import type { PlaceData } from '@orus.eu/dimensions'
import {
  borderStroke,
  colors,
  colorTokens,
  SearchBar,
  spacing,
  Spinner,
  Text,
  TextInputLabelWrapper,
  useDebounce,
} from '@orus.eu/pharaoh'
import { isSuccess } from '@orus.eu/result'
import { useEffect, useRef, useState } from 'react'
import { buildPlaceSelectionUpdate, type SiretSearchElementProps } from './siret-search-helper'

type SearchOption = {
  id: string
  place: PlaceData
}

export function SiretBoSearchElement({
  stateProxy,
  uiElement,
  isLoadingWhileTryCompleteStep,
  handleSubmit,
}: SiretSearchElementProps): JSX.Element {
  const [rawQuery, setRawQuery] = useState(stateProxy.readRequired(uiElement.dimensions.placeSearchQuery))
  const [debouncedRawQuery] = useDebounce(rawQuery, 1000)

  const [searchBarOptions, setSearchBarOptions] = useState<SearchOption[]>([])
  const [inputValue, setInputValue] = useState<string>('')
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const activeOptionRef = useRef<SearchOption | null>(null)
  const writePlaceSearchQuery = stateProxy.useWrite(uiElement.dimensions.placeSearchQuery)
  const placesResult = stateProxy.read(uiElement.dimensions.placeSearchResults)

  useEffect(() => {
    if (debouncedRawQuery) {
      writePlaceSearchQuery(debouncedRawQuery)
    }
  }, [debouncedRawQuery, writePlaceSearchQuery])

  useEffect(() => {
    setIsLoading(true)
    setRawQuery(inputValue)
  }, [inputValue])

  useEffect(() => {
    setIsLoading(false)
    const places = placesResult && isSuccess(placesResult) ? placesResult.output : []
    setSearchBarOptions(
      places.map((place, index) => ({
        id: `${index}`,
        place,
      })),
    )
  }, [placesResult])

  function onOptionSelect(option: SearchOption | null) {
    if (option) {
      handleSubmit(undefined, buildPlaceSelectionUpdate(option.place, uiElement))
      setInputValue('')
      setIsPopupOpen(false)
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      onOptionSelect(activeOptionRef.current)
    }
  }

  if (isLoadingWhileTryCompleteStep)
    return (
      <div
        css={css`
          display: flex;
          justify-content: center;
          margin-top: ${spacing[50]};
        `}
      >
        <Spinner size="70" />
      </div>
    )

  return (
    <TextInputLabelWrapper label={uiElement.searchText} required={uiElement.required}>
      <SearchBar
        size="small"
        options={searchBarOptions}
        isOpen={isPopupOpen}
        onOpen={() => setIsPopupOpen(true)}
        inputValue={inputValue}
        isLoading={isLoading}
        freeSolo={false}
        hasCustomEndAdornment={true}
        hidePopper={inputValue === '' || isLoading}
        getOptionLabel={(option) => option.place.siret}
        filterOptions={(options) => options}
        placeholder={'552 178 639 00132'}
        onInputChange={(_event, newValue) => {
          if (activeOptionRef.current && newValue === activeOptionRef.current.place.siret) return
          setInputValue(newValue)
        }}
        onKeyDown={handleKeyDown}
        onHighlightChange={(_event, option) => (activeOptionRef.current = option as SearchOption)}
        renderOption={(props, option) => {
          const { key, ...restProps } = props
          return (
            <li
              key={key}
              {...restProps}
              css={SearchOptionCss}
              aria-label="siret-search-option"
              onClick={() => onOptionSelect(option as SearchOption)}
            >
              <span
                css={css`
                  align-items: center;
                `}
              >
                <Text className="mostImportantText" variant="body2Medium">
                  {option.place.name ?? option.place.companyName ?? option.place.siret}
                </Text>
                <Text variant="body2" color={colorTokens['color-text-base-basic']}>
                  {option.place.siret}
                </Text>
                <Text variant="body2" color={colorTokens['color-text-base-basic']}>
                  {formatFrenchAddress1Line(option.place)}
                </Text>
              </span>
            </li>
          )
        }}
        clearInputValue={() => setInputValue('')}
      />
    </TextInputLabelWrapper>
  )
}

const SearchOptionCss = css`
  border-bottom: ${borderStroke[20]} solid ${colorTokens['color-stroke-base']};
  display: flex;
  padding: ${spacing['30']} ${spacing['50']};
  align-items: center;
  gap: ${spacing['50']};
  align-self: stretch;

  cursor: pointer;

  &:hover {
    background-color: ${colors.blue[100]};
  }
`
