import { css } from '@emotion/react'
import type { SiretSearchSubscriptionUiElement } from '@orus.eu/dimensions'
import { spacing, Spinner } from '@orus.eu/pharaoh'
import { ifStateProxy } from '../../if-state-proxy'
import { SiretBoSearchElement } from './SiretBoSearchElement'
import { SiretSOSearchElement } from './SiretSOSearchElement'

export const SiretSearchSubscriptionUiElementBlock = ifStateProxy<SiretSearchSubscriptionUiElement>(
  function SiretSearchSubscriptionUiElementBlock({
    handleSubmit,
    uiElement,
    stateProxy,
    synchronizing,
    isLoadingWhileTryCompleteStep,
    context,
  }) {
    if (isLoadingWhileTryCompleteStep)
      return (
        <div
          css={css`
            display: flex;
            justify-content: center;
            margin-top: ${spacing[50]};
          `}
        >
          <Spinner size="70" />
        </div>
      )

    return (
      <>
        {context === 'backoffice' ? (
          <SiretBoSearchElement
            stateProxy={stateProxy}
            uiElement={uiElement}
            isLoadingWhileTryCompleteStep={isLoadingWhileTryCompleteStep}
            handleSubmit={handleSubmit}
            synchronizing={synchronizing}
          />
        ) : (
          <SiretSOSearchElement
            stateProxy={stateProxy}
            uiElement={uiElement}
            isLoadingWhileTryCompleteStep={isLoadingWhileTryCompleteStep}
            handleSubmit={handleSubmit}
            synchronizing={synchronizing}
          />
        )}
      </>
    )
  },
)
