import type { GroupCategory } from '@orus.eu/backend/src/events/collected-file/collected-file-event'
import type { GroupStatus } from '@orus.eu/backend/src/services/collected-documents/collected-file-service'
import {
  subscriptionDocumentTypes,
  type SubscriptionDocumentsUiElement,
  type SubscriptionDocumentType,
  type SubscriptionDocumentTypeNoteKey,
} from '@orus.eu/dimensions'
import { ensureError } from '@orus.eu/error'
import { FlexColumn, spacing, useCrash, useDialogVisibility } from '@orus.eu/pharaoh'
import { useCallback, useMemo, useState } from 'react'
import { trpc, trpcReact } from '../../../../../client'
import { getCollectedFileDownloadLink } from '../../../../../lib/download-links'
import { FileViewerDialog, type ViewerFile } from '../../../backoffice/common/FileViewerDialog'
import { ifStateProxy } from '../../if-state-proxy'
import { FileGroup } from './file-group-table'
import type { File } from './types'

export const SubscriptionDocumentsUiElementBlock = ifStateProxy<SubscriptionDocumentsUiElement>(
  function SubscriptionDocumentsUiElementBlock({ uiElement, stateProxy, subscriptionId, versionedSubscriptionId }) {
    const viewerDialog = useDialogVisibility('viewer')
    const crash = useCrash()
    const neededDocuments = stateProxy.readRequired(uiElement.dimensions.neededSubscriptionDocuments)
    const globalOverviewNote = stateProxy.read(uiElement.dimensions.globalOverviewNote)
    const [selectedFileId, setSelectedFileId] = useState<string | undefined>(undefined)

    const collectedFiles = trpcReact.collectedFile.getAllFiles.useQuery({ subscriptionId })

    const filesPerCategory = Object.values(collectedFiles.data?.files ?? {}).reduce<
      Partial<Record<GroupCategory | 'none', File[]>>
    >((acc, file) => {
      if (file.deleted) return acc
      const groupCategory = (file.groupId ? collectedFiles.data?.groups[file.groupId]?.category : 'none') ?? 'none'

      acc[groupCategory] = (acc[groupCategory] ?? []).concat(file)
      return acc
    }, {})

    const allFilesSortedByCategory = useMemo(() => {
      const fileIndexPerCategory: Map<GroupCategory | 'none', number> = new Map()
      return Object.values(collectedFiles.data?.files ?? {})
        .flatMap<ViewerFile>((file) => {
          if (file.deleted) return []
          const group = file.groupId ? collectedFiles.data?.groups[file.groupId] : undefined
          const fileIndexForCategory = fileIndexPerCategory.get(group?.category ?? 'none') ?? 1
          fileIndexPerCategory.set(group?.category ?? 'none', fileIndexForCategory + 1)

          return [
            {
              uri: getCollectedFileDownloadLink(file.id),
              fileType: file.mimeType,
              metadata: {
                file,
                fileIndexForCategory,
                group: group ?? null,
              },
            },
          ]
        })
        .sort((a, b) => {
          const aCategory = a.metadata.group?.category ?? 'none'
          const bCategory = b.metadata.group?.category ?? 'none'
          if (aCategory !== bCategory) {
            return aCategory < bCategory ? -1 : 1
          }
          return a.metadata.fileIndexForCategory - b.metadata.fileIndexForCategory
        })
    }, [collectedFiles.data?.files, collectedFiles.data?.groups])

    const selectedFile = useMemo(() => {
      return allFilesSortedByCategory.find((file) => file.metadata.file.id === selectedFileId)
    }, [allFilesSortedByCategory, selectedFileId])

    const handleOnReviewFinished = useCallback(() => {
      trpc.tracking.sendDocumentReviewMessage
        .mutate({
          event: 'subscription_review_finished',
          subscription_id: subscriptionId,
          versioned_subscription_id: versionedSubscriptionId ?? subscriptionId + '/0',
          hubspotSubscriptionVersionId: versionedSubscriptionId ?? subscriptionId + '/0',
          global_document_note: globalOverviewNote ?? '',
          ...subscriptionDocumentTypes.reduce(
            (acc, documentType) => {
              const group = Object.values(collectedFiles.data?.groups ?? {}).find(
                (group) => group.category === documentType,
              )

              acc[documentType] = group?.status ?? null
              acc[`note_${documentType}`] = group?.notes?.value ?? null

              return acc
            },
            {} as Record<SubscriptionDocumentType, GroupStatus | null> &
              Record<SubscriptionDocumentTypeNoteKey, string | null>,
          ),
        })
        .catch((err) => {
          crash(ensureError(err))
        })
      viewerDialog.hide()
    }, [crash, collectedFiles.data?.groups, subscriptionId, versionedSubscriptionId, viewerDialog, globalOverviewNote])

    const viewFile = useCallback(
      (fileId: string) => {
        setSelectedFileId(fileId)
        viewerDialog.show()
      },
      [viewerDialog],
    )

    return (
      <>
        <FlexColumn gap={spacing[60]}>
          <FileGroup
            subscriptionId={subscriptionId}
            group={null}
            category={null}
            initiallyCollapsed={false}
            files={filesPerCategory.none ?? []}
            stateLoading={collectedFiles.isPending}
            allowedCategories={neededDocuments}
            invalidate={() => {
              void collectedFiles.refetch()
            }}
            onViewClick={viewFile}
          />

          {neededDocuments.map((neededDocument) => {
            const group = Object.values(collectedFiles.data?.groups ?? {}).find(
              (group) => group.category === neededDocument,
            )

            return (
              <FileGroup
                key={neededDocument}
                subscriptionId={subscriptionId}
                group={group ?? null}
                category={neededDocument}
                initiallyCollapsed={true}
                files={filesPerCategory[neededDocument] ?? []}
                stateLoading={collectedFiles.isPending}
                allowedCategories={neededDocuments}
                invalidate={() => {
                  void collectedFiles.refetch()
                }}
                onViewClick={viewFile}
              />
            )
          })}
        </FlexColumn>

        {selectedFile && viewerDialog.visible ? (
          <FileViewerDialog
            selectedFile={selectedFile}
            onSelectedFileChange={(file) => setSelectedFileId(file.metadata.file.id)}
            allFiles={allFilesSortedByCategory}
            totalForCategory={filesPerCategory[selectedFile.metadata.group?.category ?? 'none']?.length ?? 0}
            allowedCategories={neededDocuments}
            close={viewerDialog.hide}
            onReviewFinished={handleOnReviewFinished}
            invalidate={() => {
              void collectedFiles.refetch()
            }}
          />
        ) : null}
      </>
    )
  },
)
