import styled from '@emotion/styled'
import { type QuotePageSubtitleSubscriptionUiElement } from '@orus.eu/dimensions'
import { Button, colorTokens, FlexColumn, spacing, Text, useDialogVisibility } from '@orus.eu/pharaoh'
import { ifStateProxy } from '../../if-state-proxy'
import { ActivitiesDetailDialog } from './components/ActivitiesDetailDialog'

export const QuotePageSubtitleElementBlock = ifStateProxy<QuotePageSubtitleSubscriptionUiElement>(
  function QuotePageSubtitleElementBlock({ uiElement, stateProxy }) {
    const {
      show: showActivitiesDetail,
      hide: hideActivitiesDetail,
      visible: isActivitiesDetailVisible,
    } = useDialogVisibility('activities-detail')

    const complementaryActivities = stateProxy.readRequired(uiElement.dimensions.complementaryActivities)
    const complementaryActivitiesCount = complementaryActivities.length

    return (
      <FlexColumn>
        <ActivityTitle variant="body1" color={colorTokens['color-text-base-basic']}>
          {stateProxy.readRequired(uiElement.dimensions.activity).displayName}
        </ActivityTitle>
        {complementaryActivitiesCount > 0 ? (
          <ComplementaryActivitiesButton variant={'text'} size={'small'} onClick={showActivitiesDetail}>
            {complementaryActivitiesCount > 1
              ? `Vos ${complementaryActivitiesCount} autres activités`
              : 'Votre autre activité'}
          </ComplementaryActivitiesButton>
        ) : null}
        {isActivitiesDetailVisible ? (
          <ActivitiesDetailDialog
            activities={stateProxy.readRequired(uiElement.dimensions.complementaryActivities)}
            onClose={hideActivitiesDetail}
          />
        ) : null}
      </FlexColumn>
    )
  },
)

const ComplementaryActivitiesButton = styled(Button)`
  align-self: flex-start;
  margin-top: ${spacing[30]};
`

const ActivityTitle = styled(Text)`
  margin-top: ${spacing[30]};
  display: flex;
  flex-wrap: wrap;
`
