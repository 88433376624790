import { css } from '@emotion/react'
import type { DetailedSheetUiElement } from '@orus.eu/dimensions'
import { Badge, Button, spacing, Text } from '@orus.eu/pharaoh'
import { useParams } from '@tanstack/react-router'
import { useNavigateTo } from '../../../../../lib/hooks/use-navigate-to-route'
import { usePermissions } from '../../../../../lib/use-permissions'
import {
  detailedSheetCompleteStatusBadgeVariantColor,
  detailedSheetCompleteStatusText,
} from '../../../backoffice/utils/detailed-sheet-common'
import { ifStateProxy } from '../../if-state-proxy'

export const DetailedSheetUiElementBlock = ifStateProxy<DetailedSheetUiElement>(function DetailedSheetUiElementBlock({
  stateProxy,
  uiElement,
}) {
  const params = useParams({ strict: false })

  const subscriptionId = params.subscriptionId

  const isPlatform = usePermissions().type === 'platform'

  const detailedSheetStatus = stateProxy.read(uiElement.dimensions.detailedSheetCompleteStatus)

  const onDetailedSheetClick = useNavigateTo({
    to: '/bak/v2-pending-subscriptions/$subscriptionId/detailed-sheet',
    params: { subscriptionId },
  })

  return isPlatform ? (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: ${spacing[40]};
        `}
      >
        <Text variant="body2">Statut</Text>

        <Badge variant={detailedSheetCompleteStatusBadgeVariantColor[detailedSheetStatus ?? 'pending']}>
          {detailedSheetCompleteStatusText[detailedSheetStatus ?? 'pending']}
        </Badge>
      </div>
      <Button variant="secondary" size="small" avatarPosition="left" onClick={onDetailedSheetClick}>
        Ouvrir la fiche détaillée
      </Button>
    </div>
  ) : (
    <></>
  )
})
